
  import { bus } from "@/main";
  import { Action, Getter } from "vuex-class";
  import { HasSpotlight } from "@/mixins/has-spotlight";
  import { Component, Mixins } from "vue-property-decorator";
  import { activityIsDossier } from "@/utils/model-helpers";
  import type { Office } from "@/store/modules/office.store";

  @Component({
    components: {
      userImage: () => import("@/components/general/user-image.vue"),
      LayoutUser: () => import("@/layouts/presets/user.vue"),
      ActivityCreateModal: () => import("@/components/modals/create-activity.vue"),
      DocumentUploadModal: () => import("@/components/modals/upload-document.vue"),
      MoreSlideout: () => import("@/components/general/more-slideout.vue"),
    },
  })
  export default class PageUser extends Mixins(HasSpotlight) {
    @Getter("auth/me") account!: CurrentAccount;
    @Getter("auth/isAdmin") isAdmin!: boolean;
    @Getter("activity/viewing") activity!: Activity;

    @Getter("office/all") offices!: Office[];
    @Getter("currentOffice") officeId!: number;
    @Action("office/index") indexOffices!: () => Promise<Office[]>;

    @Action("setOfficeId") setOfficeId!: (officeId: number) => void;

    showOfficeToggle = false;

    showOfficeToggleMobile = false;

    get isViewingProspect() {
      if (!this.activity) {
        return false;
      }

      return !activityIsDossier(this.activity);
    }

    get isViewingWonDossier() {
      if (!this.activity) {
        return false;
      }

      return activityIsDossier(this.activity);
    }

    get hasMultipleOffices() {
      if (this.isAdmin || this.account.offices!.length > 1) {
        return true;
      }

      return false;
    }

    get office() {
      if (this.includedOffices.length === 0) {
        return false;
      }

      return this.includedOffices.find((o) => o.id === this.officeId);
    }

    get includedOffices() {
      if (!this.account) {
        return this.offices;
      }

      return this.offices.filter((o) => this.account.offices!.includes(o.id));
    }

    get showSidebar() {
      if (
        ["dossier-property-create", "dossier-property-create-desktop", "dossier-property-viewer", "dossier-bid-create", "dossier-bid-update", "dossier-bid-update-approve"].includes(
          `${this.$route.name}`,
        )
      ) {
        return false;
      }

      return true;
    }

    mounted() {
      if (this.hasMultipleOffices) {
        this.indexOffices();
      }
    }

    createActivity() {
      bus.$emit("show-activity-create");
    }

    showMoreSlideout() {
      bus.$emit("show-more-slideout");
    }

    switchOffice(id: number) {
      this.setOfficeId(id);

      window.location.href = "/";

      this.showOfficeToggle = false;
    }

    reloadApp() {
      // @ts-ignore
      window.location.reload(true);
    }

    generateTooltip(content: string) {
      return { content: content, offset: [0, 12], delay: { show: 100, hide: 100 }, placement: "right" };
    }
  }
