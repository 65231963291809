import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Ref } from "vue-property-decorator";

@Component({
    components: {
        Spotlight: () => import("@/components/general/spotlight.vue"),
    },
})
export class HasSpotlight extends Vue {
    @Ref("spotlight") spotlight!: any;

    /**
     * Open the spitlight component using his component reference.
     *
     * @returns void
     */
    openSpotlight() {
        if (!this.spotlight) {
            return;
        }

        this.spotlight.openSpotlight();
    }
}
